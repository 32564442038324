<template>
  <div
    class="flex justify-center py-1 w-[55px] rounded-full text-xs"
    :class="[style.color, style.background]">
    ציון: {{ score }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

defineEmits(['onCheckClick', 'updateScore']);

const props = defineProps({
  score: {
    type: Number,
    default: null,
  },
});

const levels = [
  { range: [0, 50], style: { color: 'text-red-500', background: 'bg-red-50' } },
  {
    range: [51, 79],
    style: { color: 'text-orange-500', background: 'bg-orange-50' },
  },
  {
    range: [80, 120],
    style: { color: 'text-green-700', background: 'bg-green-50' },
  },
];

const isInRange = (value, [min, max]: number[]) => value >= min && value <= max;

const style = computed(() => {
  return levels.find(({ range }) => isInRange(props.score, range))?.style ?? {};
});
</script>
