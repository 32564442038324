<template>
  <div class="flex flex-col flex-1">
    <div
      class="bg-[#EDF4FF] rounded-md relative px-[24px] pt-[28px] flex justify-between mb-5">
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-1">
          <span class="font-simplerBold text-[24px] text-secondary-900">
            {{ courseStore.course.caption }}
          </span>
          <span class="font-simplerRegular text-base text-secondary-800">
            /
          </span>
          <span class="font-simplerRegular text-sm text-secondary-800">
            {{ group?.owner_id.fullName }}
          </span>
          <ProgressInfo
            :progress="
              Math.round(currentEnrollmentFormat.enrollment_total_progress)
            "
            :items-progress="lessonsProgress"/>
        </div>
        <div
          :dir="courseStore.course?.locale === 'en' ? 'ltr' : 'rtl'"
          class="course-description hidden"
          v-html="courseStore.course?.description"></div>
        <div class="flex items-center gap-2 mt-2">
          <PVButton
            class="flex-row-reverse h-[33px]"
            :label="enrollmentState.action"
            @click="router.push(enrollmentState.route)"/>
          <div class="flex items-center gap-1 bg-white rounded-4 px-3 py-1">
            <span class="font-semibold text-sm h-3.5">
              {{ accountStore.user.coins }} אמיתקוין
            </span>
            <img :src="CoinsBag" class="h-[25px]" />
          </div>
          <div class="flex items-center gap-3 bg-white rounded-4 px-3 py-1">
            <span
              class="font-semibold text-sm h-3.5"
              :class="[
                hasPublishedScore ? 'text-secondary-900' : 'text-[#9BAABF]',
              ]">
              ציון: {{ currentEnrollmentFormat.score ?? '/' }}
            </span>
            <img :src="GraduationHat" class="h-[25px]" />
          </div>
        </div>
      </div>
      <div class="relative min-h-[130px] min-w-[160px] mr-20">
        <GirlReadingBooks class="absolute -bottom-7 left-5 hidden" />
      </div>
    </div>
    <div class="flex flex-col flex-1 gap-3">
      <div
        class="flex items-center font-simplerRegular text-secondary-900 text-base">
        כל השיעורים
        <span class="mr-1 font-simplerRegular text-[#9BAABF] text-base">
          ({{ currentEnrollmentFormat.lessons?.length ?? 0 }})
        </span>
      </div>
      <ListView
        :items="unitsLessons"
        @item-click="item => !item.hideArrow && navigateToLesson(item.data.id)">
        <template #item="{ item }">
          <div v-if="item.items" class="pt-1 flex flex-1">
            <ListView
              :items="[item]"
              invert-style
              @item-click="
                item =>
                  !item.hideArrow &&
                  navigateToProject(item.id as string, currentCourse)
              ">
              <template #section-title="{ section }">
                <div
                  v-if="section.data?.published_score"
                  class="text-secondary-975 text-sm font-simplerRegular mr-4">
                  <CustomScore
                    v-if="section.data.published_score != null"
                    :score="section.data.published_score"/>
                </div>
              </template>
              <template #item="{ item }">
                <ItemInfo
                  :item="item.data"
                  :status="
                    item.data.locked
                      ? projectStatuses.LOCKED
                      : projectStatuses[item.data.status]
                  "/>
              </template>
            </ListView>
          </div>
          <ItemInfo
            v-else
            :item="item.data"
            :status="
              Object.values(lessonStatuses).find(({ condition }) =>
                condition(item.data),
              )
            "
            :coins="lessonCoins[item.data.lessonIndex]"
            :team="lessonsTeam[item.id]"/>
        </template>
      </ListView>
    </div>
  </div>
  <SideWidget>
    <NotificationWidget />
  </SideWidget>
</template>

<script setup lang="ts">
import ListView from '@amit/foundation/List/ListView.vue';
import GirlReadingBooks from '../assets/girlReadingBooks.svg?component';
import GraduationHat from './../assets/graduationHat.svg?url';
import CoinsBag from '../assets/coinsBag.svg?url';
import ItemInfo from '/@/components/ItemInfo.vue';
import { computed, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAccountStore } from '/@/store/account';
import { useCourseStore } from '/@/store/course';
import { formatLearningHistoryDetails } from '/@/services/accounts';
import NotificationWidget from '/@/components/NotificationWidget.vue';
import fetchSkills from '/@/services/content';
import { get, map, sumBy } from 'lodash';
import pluralize from 'pluralize';
import PVButton from 'primevue/button';
import { watchEffect } from 'vue';
import { IEnrollment } from '/@/types/interfaces';
import { SideWidget } from '@amit/layout';
import { ProgressInfo } from '@amit/components';
import { useProject } from '../composables/useProject';
import CustomScore from '../components/CustomScore.vue';

const accountStore = useAccountStore();
const courseStore = useCourseStore();
const router = useRouter();
const route = useRoute();
const selectedPersonas = ref({ assistant: {} as any, mentor: {} as any });
const dataPersonas = ref();
const skilyId = '63a1001b6376e0feb759b966';
const currentCourse = computed(() => route.params.course as string);

const currentEnrollment = computed(
  () => accountStore.getCurrentEnrollment(currentCourse.value) as IEnrollment,
);
const currentEnrollmentFormat: any = computed(() =>
  formatLearningHistoryDetails(currentEnrollment.value),
);
const currentLesson = computed(() =>
  accountStore.getCurrentLesson(currentEnrollment.value),
);

const group = computed(
  () =>
    accountStore.groups?.find(
      group => group.id === currentEnrollment.value?.group,
    ) as any,
);

const { assessments, navigateToProject } = useProject(currentEnrollment, group);

const enrollmentState = computed(() => {
  if (
    currentEnrollment.value?.course?.poll &&
    currentEnrollment.value?.status == 'pending'
  ) {
    return {
      title: 'יוצאים לדרך',
      action: 'בואו נתחיל בשיעור >',
      route: {
        name: 'onboarding', // should be generic "surveys"
        params: currentEnrollment.value.course.poll,
      },
    };
  }
  const route =
    courseStore?.course?.theme?.landingPage?.image ||
    currentCourse.value === skilyId
      ? {
          name: 'course-home',
          params: { course: currentCourse.value },
        }
      : {
          name: 'skill-lesson',
          params: {
            lesson: currentLesson.value,
          },
        };

  return {
    title: 'ממשיכים בתנועה!',
    action: 'בואו נמשיך בשיעור >',
    route,
  };
});

const lessonCoins = computed(() => {
  return map(courseStore.course.outline, lesson => {
    return sumBy(lesson.sections, 'coins');
  });
});

const courseModule = computed(() => {
  return accountStore.getCourseModule(currentCourse.value);
});

const hasPublishedScore = computed(() => {
  return typeof currentEnrollmentFormat.value?.score === 'number';
});

const team = computed(() => {
  const teams = courseModule.value?.teams ?? [];
  return (
    teams.find(team => {
      return team.students.some(student => student.id === accountStore.user.id);
    })?.students ?? []
  );
});

const lessonsTeam = computed(() => {
  const lessonsSummary = courseModule.value?.summary ?? [];

  return lessonsSummary.reduce((summary, { lesson, teams_enabled }) => {
    summary[lesson] = teams_enabled ? team.value : [];
    return summary;
  }, {});
});

// const showPersonas = computed(() => {
//   return (
//     courseStore.course.caption === 'ניהול עצמי' &&
//     selectedPersonas.value.assistant?.id
//   );
// });

const getPersonaData = type => {
  const personaId = get(accountStore, `enrollments[0][${type}]`, '');
  return dataPersonas.value[pluralize(type)].find(
    item => item.id === personaId,
  );
};

onMounted(() => {
  courseStore.fetchCourse(currentCourse.value);
  accountStore.setEnrollments(currentCourse.value);
  accountStore.getUser(true);
  const { onSuccess } = fetchSkills();
  onSuccess(({ data }) => {
    dataPersonas.value = data[0];
    selectedPersonas.value['mentor'] = getPersonaData('mentor');
    selectedPersonas.value['assistant'] = getPersonaData('assistant');
  });
});

watchEffect(() => {
  if (!group.value?.id) {
    return;
  }

  accountStore.setCourseModules(group.value.id, currentCourse.value);
});

const lessonsProgress = computed(() =>
  currentEnrollmentFormat.value.lessons?.map(lesson => lesson.progress),
);

const projectStatuses = {
  LOCKED: {
    id: 'LOCKED',
    label: 'נעול',
    color: '#9BAABF',
    condition: item => item?.locked,
  },
  in_progress: {
    id: 'in_progress',
    label: 'בלמידה',
    color: '#0FA9FF',
    condition: item => item?.status === 'in_progress',
  },
  awaiting_review: {
    id: 'awaiting_review',
    label: 'ממתין לבדיקה',
    color: '#0FA9FF',
    condition: item => item?.status === 'awaiting_review',
  },
  finished: {
    id: 'finished',
    label: 'הושלם',
    color: '#3F9F44',
    condition: item => item?.status === 'finished',
  },
};

const lessonStatuses = {
  LOCKED: {
    id: 'LOCKED',
    label: 'נעול',
    color: '#9BAABF',
    condition: item => item?.locked,
  },
  COMPLETED: {
    id: 'COMPLETED',
    label: 'הושלם',
    color: '#3F9F44',
    condition: item => item?.progress === 100,
  },
  NOT_STARTED: {
    id: 'NOT_STARTED',
    label: 'פתוח',
    color: '#0FA9FF',
    condition: item => item?.progress < 100,
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    label: 'בלמידה',
    color: '#0FA9FF',
    condition: item => item?.progress > 0 && item?.progress < 100,
  },
};

const unitsLessons = computed(() => {
  const units = currentEnrollmentFormat.value.units?.map(unit => ({
    title: unit.title,
    items: unit.lessons.map((lesson, index) => ({
      title: lesson.title,
      id: lesson.id,
      data: {
        ...lesson,
        lessonIndex: index,
      },
      hideArrow: lesson.locked,
      disabled: lesson.locked,
    })),
  }));
  return [
    ...(units ?? []),
    ...(Object.keys(assessments.value).length ? [assessments.value] : []),
  ];
});

const navigateToLesson = (lessonId: string) => {
  router.push({
    name: 'skill-lesson',
    params: { lesson: lessonId },
  });
};
</script>

<style>
.course-description {
  p {
    @apply font-simplerRegular text-sm text-secondary-800 !important;
  }

  p a {
    @apply inline;
  }
}
</style>
