import { computed, ref, watchEffect, Ref } from 'vue';
import { IEnrollment } from '../types/interfaces';
import { fetchProjectEnrollments } from '../services/projects';
import { useAccountStore } from '../store/account';
import { useRouter } from 'vue-router';

export function useProject(enrollment: Ref<IEnrollment>, group: Ref<any>) {
  const accountStore = useAccountStore();
  const router = useRouter();

  const projectEnrollment = ref();

  const projectId = computed(() => {
    const courseProject = enrollment.value?.course?.assessments?.find(
      assessment => assessment.type === 'project',
    );
    return courseProject?.reference ?? '';
  });

  watchEffect(() => {
    if (!projectId.value || !group.value?.id) {
      return;
    }

    const { onSuccess } = fetchProjectEnrollments(
      projectId.value,
      accountStore.user.id,
      group.value.id,
    );
    onSuccess(({ data }) => {
      if (data?.length) {
        projectEnrollment.value = data[0];
      }
    });
  });

  const navigateToProject = (stageId: string, course: string) => {
    router.push({
      name: 'skill-project',
      params: {
        project: projectEnrollment.value?.project,
        stage: stageId,
      },
      query: { course },
    });
  };

  const projectStages = computed(() => {
    return projectEnrollment.value?.summary?.map(item => ({
      ...item,
      id: item.stage.id,
    }));
  });

  const assessments = computed(() => {
    if (!projectStages.value?.length) {
      return {};
    }
    return {
      title: 'תירגול מעשי',
      items: [
        {
          id: projectId.value,
          title: '1. פרויקט מעשי',
          hideArrow: true,
          data: {
            published_score: projectEnrollment.value?.published_score,
          },
          items: projectStages.value.map(
            ({ stage, locked, status }, index) => ({
              id: stage.id,
              title: stage.title,
              hideArrow: locked,
              disabled: locked,
              data: {
                id: stage.id,
                title: stage.title,
                locked: locked,
                lessonIndex: index,
                status,
                progress: status === 'finished' ? 100 : 0,
              },
            }),
          ),
        },
      ],
    };
  });

  return {
    projectId,
    projectEnrollment,
    projectStages,
    assessments,
    navigateToProject,
  };
}
