<template>
  <div class="flex flex-1 items-center gap-3">
    <div class="flex flex-grow-0">
      <LockKeyhole v-if="locked" :size="13" class="text-secondary-900" />
      <BookOpen v-else :size="13" class="text-secondary-900" />
    </div>
    <div class="flex w-40 sm:w-80 md:w-40 xl:w-80">
      <p
        v-tooltip.top="item.title"
        class="font-simplerRegular text-secondary-900 text-sm truncate">
        {{ item.title }}
      </p>
    </div>
    <div
      class="flex flex-[2] gap-2 font-simplerRegular text-secondary-900 text-xs min-w-[203px]">
      <div class="w-30">
        <div class="flex">
          <ProgressInfo
            :progress="Math.round(item.progress)"
            :color="status.color"
            :label="status.label"/>
        </div>
      </div>
      <CustomScore v-if="item.score != null" :score="item.score" />
    </div>
    <div
      v-if="!locked && coins != null"
      class="flex flex-1 font-simplerRegular text-secondary-900 text-xs min-w-[60px]">
      <AmitCoin class="h-[14px] w-[14px] ml-1" />
      {{ `${coins} אמית קוינס` }}
      <!-- TODO: this is the coins info or lesson.coins ?? -->
    </div>
    <div
      class="flex flex-1 font-simplerRegular text-secondary-900 text-xs min-w-[60px]">
      <div v-if="team.length">
        <div
          class="flex gap-2 items-center bg-[#F4F7FB] p-1 rounded-sm cursor-pointer"
          @click="
            e => {
              e.stopPropagation();
              e.preventDefault();
              togglePanel(e);
            }
          ">
          <UsersIcon class="stroke-secondary-800 w-5" />
          <span class="text-secondary-800">למידה בקבוצות</span>
        </div>
        <OverlayPanel ref="teamPanel">
          <div class="p-3 w-[180px]">
            <div class="flex justify-between items-center mb-1.5">
              <div class="flex gap-1 items-center">
                <span class="text-secondary-900 font-simplerBold">
                  החברים לקבוצה
                </span>
                <span class="text-[#9BAABF]">{{ ` (${team.length}) ` }}</span>
              </div>
              <CloseIcon
                class="h-3.5 w-3.5 cursor-pointer stroke-secondary-900"
                @click.stop="togglePanel"/>
            </div>
            <Divider />
            <div dir="ltr" class="h-[160px] mt-3 overflow-y-auto">
              <div dir="rtl" class="flex flex-col gap-2 px-3">
                <div
                  v-for="student in team"
                  :key="student.id"
                  class="flex gap-2 items-center">
                  <img
                    :src="student.avatar || defaultAvatarImage"
                    :alt="student.fullName"
                    class="h-5 w-5 rounded-full"/>
                  <span>{{ student.fullName }}</span>
                </div>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AmitCoin from './../assets/amitCoin.svg?component';
import { type PropType, computed, ref } from 'vue';
import { BookOpen, LockKeyhole } from 'lucide-vue-next';
import { ProgressInfo } from '@amit/components';
import { Users as UsersIcon, X as CloseIcon } from 'lucide-vue-next';
import { defaultAvatarImage } from '@/utils/helpers';
import OverlayPanel from 'primevue/overlaypanel';
import Divider from 'primevue/divider';
import { IStudent } from '/@/types/interfaces';
import CustomScore from './CustomScore.vue';

type Item = {
  id: number;
  title: string;
  locked: boolean;
  progress: number;
  score: number;
};

type Status = {
  id: string;
  label: string;
  color: string;
  condition: (item: Item) => boolean;
};

const props = defineProps({
  item: {
    type: Object as PropType<Item>,
    required: true,
  },
  coins: {
    type: Number,
    default: null,
  },
  team: {
    type: Array as PropType<IStudent[]>,
    default: () => [],
  },
  route: {
    type: Object,
    default: null,
  },
  status: {
    type: Object as PropType<Status>,
    default: null,
  },
});

const teamPanel = ref();

const locked = computed(() => props.status.id === 'LOCKED');

const togglePanel = event => {
  teamPanel.value.toggle(event);
};
</script>
